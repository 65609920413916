@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@queries' as *;

.content-widget {
  &__items-block {
    @include mainContentStyles;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px 14px;
    gap: 14px;
    min-width: 320px;

    @include min-428-break {
      padding: 14px;
      gap: 14px;
    }

    @include min-744-break {
      gap: 24px;
      padding: 14px 28px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(142px, 1fr));
      justify-content: center;
      gap: 8px;
      height: 100%;

      @include min-428-break {
        grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
        gap: 12px;
      }

      @include min-744-break {
        grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
      }
    }
  }
}
